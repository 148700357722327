/* You can add global styles to this file, and also import other style files */
.margin-left-5 {
    margin-left: 5px !important;
}

.margin-right-5 {
    margin-right: 5px !important;
}

.margin-top-5 {
    margin-top: 5px !important;
}

.margin-top-15 {
    margin-top: 15px !important;
}

.margin-bottom-5 {
    margin-bottom: 5px !important;
}

.margin-bottom-5 {
    margin-bottom: 5px !important;
}

.margin-bottom-15 {
    margin-bottom: 15px !important;
}

.padding-left-5 {
    padding-left: 5px !important;
}

.padding-right-5 {
    padding-right: 5px !important;
}

.padding-top-5 {
    padding-top: 5px !important;
}

.padding-bottom-5 {
    padding-bottom: 5px !important;
}

.width-percent-5 {
    width: 5%;
}

.width-percent-10 {
    width: 10%;
}

.width-percent-15 {
    width: 15%;
}

.width-percent-20 {
    width: 20%;
}

.width-percent-30 {
    width: 30%;
}

.width-percent-40 {
    width: 40%;
}

.width-percent-50 {
    width: 50%;
}

.width-percent-100 {
    width: 100%;
}

/* Sweet alert */

.swal2-title {
    display: inline-block !important;
}

body.swal2-height-auto {
    height: inherit !important;
}

.swal2-popup.swal2-toast {
    padding: 10px 12px !important;
    display: block !important;
}

.swal2-popup.swal2-toast .swal2-header,
.swal2-popup.swal2-toast .swal2-title,
.swal2-popup.swal2-toast .swal2-content {
    text-align: left !important;
    margin: 0 !important;
}

.swal2-popup.swal2-toast .swal2-title {
    margin-bottom: 4px !important;
}

.swal2-icon.swal2-info::before,
.swal2-icon.swal2-warning::before,
.swal2-icon.swal2-success::before,
.swal2-icon.swal2-error::before {
    content: "";
}

@keyframes glowing {
    0% {
        opacity: 0.3;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0.3;
    }
}

.glowing {
    animation: glowing 2s infinite;
}

.root-initial-loader .overlay {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(51, 51, 51, 0.8);
    z-index: 99999 !important;
    width: 100%;
    height: 100%;
}

.root-initial-loader i {
    position: absolute;
    color: rgb(91, 167, 234);
    z-index: 100000 !important;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.root-initial-loader .fa-spin-2x {
    -webkit-animation: fa-spin 1s infinite linear;
    animation: fa-spin 1s infinite linear;
}
ngx-spinner .loading-text {
    font-weight: bold;
    color: white;
    font-size: 1.3em;
}

bs-days-calendar-view + bs-days-calendar-view {
    margin-left: 10px;
}

bs-days-calendar-view {
    display: inline-block;
    border-radius: 4px 0 0 4px;
}

@media (max-width: 768px) {
    .btn-md-icon {
        margin-right: 0 !important;
        padding-right: 0 !important;
    }
}


html[data-bs-theme=dark] {
    --kt-text-muted: #9D9DA6;
}

[data-kt-app-sidebar-hoverable=true][data-kt-app-sidebar-minimize=on] .app-sidebar:not(.animating) .menuSearchBarInput {
    opacity: 0;
}

[data-kt-app-sidebar-hoverable=true][data-kt-app-sidebar-minimize=on] .app-sidebar:hover:not(.animating) .menuSearchBarInput {
    opacity: 1;
}

label.required:after {
    content: "*";
    color: var(--kt-form-label-color) !important;
}

.btn i.flaticon-search-1{
    padding-right:0!important;
}

li.breadcrumb-item::after{
    padding-left:0.5rem;
}

ul.p-autocomplete-items{
    margin-bottom: 0!important;
    padding-left: 0!important;
}

/*Style for Excel Operations Buttons */
.dropdown-excel-operations .p-button {
    display: block;
    margin: 0;
    text-align: left;
    border-radius: 0;
    transition: none !important;
    margin-left: -11px;
    background-color: #fff;
    color: #181C32 !important;
    font-size: 13px !important;
    font-weight: 400 !important;
    margin-left: 0px;
    margin-right: 0px !important;
}
.dropdown-excel-operations .p-fileupload-choose.p-button.p-state-hover, .p-fileupload-choose.p-button.p-state-focus, .p-fileupload-choose.p-button.p-state-active {
    background-color: #eee;
}
.dropdown-excel-operations a:hover span.p-button, .dropdown-excel-operations a:hover div.p-fileupload-buttonbar.p-widget-header.p-corner-top {
    background-color: #f4f5f8 !important;
}
.dropdown-excel-operations a.no-padding {
    padding: 0px !important;
}
.dropdown-excel-operations a .p-button-text {
    margin-left: 5px !important;
}
.dropdown-excel-operations .p-button-label {
    font-weight: inherit !important;
}
.dropdown-excel-operations .p-fileupload-choose:not(.p-state-disabled) {
    border: none;
}
.dropdown-excel-operations .p-fileupload-choose:not(.p-state-disabled):hover, .dropdown-excel-operations .p-fileupload-choose:not(.p-state-disabled):focus, .dropdown-excel-operations .p-fileupload-choose:not(.p-state-disabled):active {
    background-color: #f4f5f8 !important;
    color: #b2b1c5;
    box-shadow: none !important;
    border: none;
}
.dropdown-excel-operations div.p-fileupload-buttonbar.p-widget-header.p-corner-top {
    border: none;
    background: #fff;
    padding-bottom: 3px !important;
    padding-top: 3px !important;
}
.dropdown-excel-operations div.p-fileupload-buttonbar.p-widget-header.p-corner-top:hover {
    background-color: #f4f5f8 !important;
}
.dropdown-excel-operations div.p-fileupload-content {
    display: none;
}
.dropdown-excel-operations div.p-fileupload-content.p-widget-content.p-corner-bottom {
    display: none;
}
.dropdown-excel-operations div.p-fileupload-buttonbar {
    border: none;
    padding: 0;
}
.dropdown-excel-operations div.p-fileupload-buttonbar.p-widget-header.p-corner-top:hover {
    background-color: #f4f5f8 !important;
}


.section-heading {
    color: #3699ff;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus {
    box-shadow: unset;
}

.p-panel .p-panel-header {
    border: none;
    background: none;
    padding: 6.5px 13px ;
}

.p-panel .p-panel-content {
    border: none;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
    line-height: 28px;
    border: none;
}

.p-accordion .p-accordion-header .p-accordion-header-link:not(.p-disabled):focus {
    box-shadow: unset;
}

.p-accordion .p-accordion-content {
    border: none;
}

.p-tag .p-tag-value {
    padding: 2px 5px;
}

.p-panel-header .p-panel-icons .p-panel-header-icon:focus {
    box-shadow: unset;
}


.p-checkbox .p-checkbox-box.p-disabled,
.p-radiobutton .p-radiobutton-box.p-disabled,
.p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container.p-disabled,
.p-chips .p-chips-multiple-container.p-disabled {
    background-color: #f3f6f9;
}

.p-checkbox-disabled .p-checkbox-box .p-checkbox-icon {
    color: #0078d4;
}

.container-grey {
    background-color: #faf9f8;
}

.primary-color {
    color: var(--primary);
}

.error-color {
    color: var(--error-color);
}

.p-multiselect-panel-width {
    width: 10rem;
}

.form-group {
    margin-bottom: 1.75rem;
}

.information-page {
    margin-top: 100px;
    margin-bottom: 50px;
}

.word-break {
    overflow-wrap: break-word;
 }

.triangle-yellow {
    color: var(--bs-yellow);
    margin-right: 5px;
}

.circle-green {
    color: #348719;
    margin-right: 5px;
}

.circle-blue {
    color: #2C78CA;
    margin-right: 5px;
}

.circle-light-blue {
    color: #79AFDA;
    margin-right: 5px;
}

.circle-red {
    color: #BC363D;
    margin-right: 5px;
}

.access-restriction-icon{
    color: gray;
}

.ngx-spinner-overlay {
    z-index: 1040 !important;
}

#kt_quick_user_toggle > span {
    font-size: 14px !important;
    font-weight: 500 !important;
}

.mz-rounded {
    border-radius: 50% !important;
}

.mz-red {
    color: var(--bs-red);
}

.mz-green {
    color: var(--bs-green);
}

.mz-orange{
    color: var(--bs-orange);
}

.mz-gray{
    color: var(--bs-gray);
}

.mz-address-info {
    font-size: 0.75em;
    color: var(--mazars5);
}

.mz-permission-spinner-center {
    font-size: 2rem;
    position: absolute;
    top: 50%;
    right: 50%;
    text-align: center;
    opacity: 1;
}

.ag-cell-focus, .ag-cell {
    border: none !important;
}

.p-tieredmenu.p-component.p-tieredmenu-overlay {
    min-width: max-content;
}

.mz-main-action .p-menuitem:first-child {
    margin: 0.3rem 0.75rem !important;
    padding: 0rem 0rem 0.4rem 0rem !important;
    cursor: pointer;
    border-bottom: 1px solid var(--mazars9);
}

.mz-main-action .p-menuitem:first-child .p-menuitem-link {
    padding: 1.6rem 2rem !important;
}

.mz-main-action .p-menuitem:first-child .p-menuitem-link:hover {
    background-color: var(--mazars1);
}

.mz-main-action .p-menuitem:first-child .p-menuitem-link > .p-menuitem-text {
    color: var(--mazars1) !important;
    font-weight: 500 !important;
}

.mz-main-action .p-menuitem:first-child .p-menuitem-link > .p-menuitem-icon {
    color: var(--mazars1) !important;
}

.mz-main-action .p-menuitem:first-child .p-menuitem-link:hover > .p-menuitem-text {
    color: var(--mazars11) !important;
}

.mz-main-action .p-menuitem:first-child .p-menuitem-link:hover > .p-menuitem-icon {
    color: var(--mazars11) !important;
}

.p-menuitem-link:hover > .p-menuitem-icon {
    color: var(--mazars1) !important;
}

.p-tieredmenu.p-component.p-tieredmenu-overlay .p-menuitem {
    cursor: pointer !important;
}

.icon-btn {
    color: var(--mazars1);
    font-size: 150%;
    cursor: pointer;
}

.icon-btn-sm {
    color: var(--mazars1);
    cursor: pointer;
}

.ag-cell-inline-editing, .ag-cell-inline-editing > .ag-cell-wrapper {
    display: block !important;
}

.mz-header-right > .ag-header-cell-comp-wrapper > .ag-cell-label-container > .ag-header-cell-label {
    flex-direction: row-reverse !important;
}

.cursor-pointer{
    cursor: pointer !important;
}


.mz-btn-icon > i {
    color: var(--mazars9);
}

.mz-btn-icon:hover > i {
    color: var(--mazars1);
}

.modal.fade {
    background: rgba(0, 0, 0, 0.5);
  }

  .modal-backdrop.fade {
    opacity: 0;
  }
.underline-link {
    color: var(--mazars5) !important;
    text-decoration: none !important;
}
.underline-link:hover {
    text-decoration: underline !important;
    text-decoration-color: var(--mazars5) !important;
}

.form-control:disabled {
    background-color: #f3f6f9 !important;
    color: #495057 !important;
    border: 1px solid #e4e6ef !important;
    opacity: 0.6 !important;
}

.p-toast-message.p-toast-message-info {
    background-color: #2196F3 !important;
    border: none !important;
}

.p-toast-message.p-toast-message-success {
    background-color: #34BFA3 !important;
    border: none !important;
}

.p-toast-message.p-toast-message-error {
    background-color: var(--bs-red) !important;
    border: none !important;
}

.p-toast-message.p-toast-message-warn {
    background-color: var(--bs-orange) !important;
    border: none !important;
}

.p-toast-icon-close {
    color: #ffffff !important;
}

.p-toast-summary {
    color: #ffffff !important;
    font-size: 1rem !important;
}

.p-toast-detail {
    margin: 0.2rem 0 0 0 !important;
    color: #ffffff !important;
    font-size: 1rem !important;
}

.p-toast-message-icon {
    color: #ffffff !important;
}

.ql-mention-list-container {
    max-height: 200px;
}

.p-tabview-nav {
    display: block !important;
}

.p-tabview-nav-content {
    position: sticky !important;
    top: 15% !important;
    overflow-y: auto !important;
}

.p-tabview-nav-container {
    margin-top: 1.2rem !important;
}

.p-tabview {
    display: flex !important;
}

@media (max-width: 991.98px) {
    .p-tabview {
        display: block !important;
    }
}

.p-tabview-nav-link {
    border: none !important;
    font-weight: var(--mazars-font-weight) !important;
}

.p-tabview-nav-link:hover {
    background-color: var(--mazars4) !important;
}

.p-highlight > .p-tabview-nav-link {
    color: var(--mazars1) !important;
    background-color: var(--mazars4) !important;
}

.p-tabview-nav-link:not(.p-disabled):focus {
    box-shadow: none !important;
}

.p-tabview-panels {
    width: 100% !important;
    padding-top: 0 !important;
}

.badge-error {
    color: #fff;
    background-color: var(--bs-red);
}

.p-skeleton {
    border-radius: 0rem !important;
}

.color-disabled {
    color: var(--mazars9) !important;
}

.alert.alert-mazars {
    background-color: #fff !important;
    border: 1px solid var(--mazars1) !important;
}



/* in-flight clone */
.gu-mirror {
    position: fixed !important;
    margin: 0 !important;
    z-index: 9999 !important;
    opacity: 0.8;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
    filter: alpha(opacity=80);
    pointer-events: none;
  }
  /* high-performance display:none; helper */
  .gu-hide {
    left: -9999px !important;
  }
  /* added to mirrorContainer (default = body) while dragging */
  .gu-unselectable {
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
  }
  /* added to the source element while its mirror is dragged */
  .gu-transit {
    opacity: 0.2;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
    filter: alpha(opacity=20);
  }


/* remove arrow in p-overlaypanel */
.p-overlaypanel:after, .p-overlaypanel:before {
    display: none !important;
}

p-table .p-scroller.p-scroller-inline {
    min-height: 100px !important;
    padding: 0 !important;
}

.entity-scroller-container {
    min-height: 100px !important;
    overflow-y: scroll !important;
}

.dashboard-scroller-container { 
    overflow: scroll !important;
}

.wide-tooltip {
    max-width: 30rem;
    white-space:pre-wrap;
}

.wrap-word {
    -ms-word-break: break-all;
    word-break: break-all;
    white-space: pre-wrap
  }